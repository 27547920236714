(function () {
	$(document).foundation();
	var columnas = $('.Height').matchHeight();
	var desktopmenu = $('div#DesktopMenu'),
		hamburguer = $('div#Hamburguer');

	hamburguer.on('click', function () {
		desktopmenu.slideToggle();
	});

	var posicion = 0,
		elBoton = $("a#toTop"),
		body = $('body'),
		menu = $('ul.scrollMenu li a');

	$(document).ready(function () {
		if (window.location.pathname == "/" && window.location.hash.length > 2)
			setTimeout(function () {
				var d = window.location.hash.toString().split('#')[1];
				var node = document.getElementById(d);
				$("html, body").animate({
					scrollTop: node.offsetTop
				}, 800);
			}, 800);
	});
	$('div#Slider-Home').slick({
		autoplay: true,
		arrows: false,
		dots: true,
		pauseOnHover: true,
		lazyLoad: 'progressive',
	});

	$("img.lazy").lazyload({
		event: "click",
		container: $("#main-image")
	});
	$('.gallery').modaal({
		type: 'image'
	});
	$('div#Slider-Testimonials').slick({
		autoplay: false,
		arrows: true,
		dots: true,
		pauseOnHover: true,
		adaptiveHeight: false,
		responsive: [{
			breakpoint: 768,
			settings: {
				adaptiveHeight: true,
				dots: false,
				arrows: false
			}
		}, ]
	});
	$('.modaal-ajax').modaal({
		type: 'ajax',
		loading_content: 'Loading content, please wait.',
		overlay_opacity: 0.8,
		background: '#fff'
	});

	menu.click(function (event) {
		if ($(this).data('go') == 0) {
			event.preventDefault();
			var elid = $('section#' + $(this).data('id')).offset().top;
			$('html, body').animate({
				scrollTop: elid
			}, 3000, 'easeOutQuint');
		}
	});

	$('html, body').bind("scroll mousedown DOMMouseScroll mousewheel keyup touchstart", function (e) {
		if (e.which > 0 || e.type === "mousedown" || e.type === "mousewheel" || e.type === 'touchstart') {
			$('html, body').stop();
		}
	});

	$(window).scroll(function (e) {
		if ($(this).scrollTop() > 100) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}
	});

	$('#toTop').click(function () {
		$("html, body").animate({
			scrollTop: 0
		}, 600);
		return false;
	});

})();
